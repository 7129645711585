/* add CSS here that is specific to the BAER app */
@use '@angular/material' as mat;

.tooltipClass {
    overflow: visible;
    background-color: #2D2926;
    border-radius: 5px;
    padding: 5px 3px 5px 5px;
    white-space: pre-line !important;
}

::ng-deep .mdc-tooltip {
    white-space: pre-line !important;
}

.mdc-tooltip__surface.mdc-tooltip__surface {
    background-color: #2D2926 !important;
    font-family: Public Sans, serif !important;
    line-height: 17px;
}

.mat-mdc-tooltip-panel-below,
.mat-mdc-tooltip-show {
    .tooltipClass::after {
        content: " ";
        position: absolute;
        /* At the top of the tooltip */
        top: -10px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #2D2926 transparent;
    }
}

.mat-mdc-tooltip-panel-above {
    .tooltipClass::after {
        content: " ";
        position: absolute;
        top: 100%;
        /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #2D2926 transparent transparent transparent;
    }
}

.mat-mdc-tooltip-panel-left {
    .tooltipClass::before {
    content: " ";
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #2D2926;
    }
}

.info-blue-field {
    color: #004785;
    margin-bottom: -2px;
    position: absolute;
    right: -30px
}

mat-form-field {
    margin: 10px;
    @include mat.all-component-densities(-3);
}

.baer-header {
    background-image: url(../../assets/img/BAER-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #216E1F
}

.baer-header h1 {
    padding: 32px 32px 60px 32px;
    margin: 0px;
    color: white;
    font-size: xx-large;
}

.baer-header h4 {
    margin: 0px;
    color: white;
    height: 0px;
    overflow: visible;
    transform: translate(32px, -50px);
}

main {
    margin-left: 32px;
    margin-right: 32px;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

td div label {
    /* fix checkbox in table */
    margin-top: 0px !important;
}

.mat-sort-header-content {
    text-align: left !important;
}

button {
    font-weight: bold !important;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;

    button {
        margin: 10px;
        max-width: 200px;
    }
}

@media (max-width:1000px) {
    .button-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            margin: 10px;
            max-width: 100%;
        }
    }
}

.search-bar {
    display: flex;
    flex-wrap: wrap;

    button {
        max-height: 44px;
        margin: 10px;
    }
}

.mdc-icon-button {
    color: #216E1F !important;
}

h1 {
    font-size: 24px !important;
    font-weight: bold !important;
}

h3 {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.record_count {
    font-weight: 700;
    padding-top: 20px;
    padding-left: 10px;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    font-size: 20px;
}

// all the chips ------------------------------------------------------------------
//PRE-SIZE UP"
.chip-0 {
    background-color: #B2C9DC !important;
}

//NEEDED"
.chip-1 {
    background-color: #FFBE2E !important;
}

//NOT NEEDED"
.chip-2 {
    background-color: #CCCCCC !important;
}

//IN PROGRESS"
.chip-3 {
    background-color: #A0DDFB !important;
}

//SUBMITTED"
.chip-4 {
    background-color: #C0D79E !important;
}

//IN REVIEW"
.chip-5 {
    background-color: #D7B3FB !important;
}

//READY FOR SUBMISSION"
.chip-6 {
    background-color: #FFBE2E !important;
}

//SIGNATURE REQUESTED"
.chip-7 {
    background-color: #FCE674 !important;
}

//COMPLETE"
.chip-8 {
    background-color: #F5F5F5 !important;
}

//READY FOR REVIEW"
.chip-9 {
    background-color: #D7B3FB !important;
}

//FORWARDED TO W.O."
.chip-10 {
    background-color: #BAC9FF !important;
}

//FUNDING APPROVED"
.chip-11 {
    background-color: #C0D79E !important;
}

//TEAM LEAD ASSIGNED
.chip-12 {
    background-color: #18FDFD !important;
    color: #004B71 !important;
}

// r for report statuses

//IN PROGRESS
.chip-r-0 {
    background-color: #A0DDFB !important;
}

//READY FOR SUBMISSION
.chip-r-1 {
    background-color: #FFBE2E !important;
}

//SUBMITTED
.chip-r-2 {
    background-color: #C0D79E !important;
}

//IN REVIEW
.chip-r-3 {
    background-color: #D7B3FB !important;
}

//SIGNATURE REQUESTED
.chip-r-4 {
    background-color: #FCE674 !important;
}

//NOT STARTED
.chip-r--10 {
    background-color: #CCCCCC !important;
    color: #2D2926 !important;
}

.chip-gis {
    background-color: #D6B794 !important;
}

.chip-new {
    background-color: #BCE492 !important;
}

.chip-complete {
    span {
        color: #216E1F !important;
    }
}

.chip-low {
    background: #F4E1BE !important;
    color: #7B5501 !important;
}

.chip-moderate {
    background-color: #CEBBA7 !important;
    color: #624402 !important;
}

.chip-high {
    background-color: #FFCFD5 !important;
    color: #B3022F !important;
}

mat-chip {
    font-weight: 600 !important;
}

// material default values -----------------------------------------
:root {
    --mdc-dialog-supporting-text-color: #2A2C2D;
    --mdc-filled-button-disabled-container-color: #C3C5C6;
    --mat-expansion-header-indicator-color: #216E1F;
    --mat-datepicker-calendar-date-selected-state-text-color: #FFFFFF;
    --mdc-outlined-text-field-label-text-color: #2A2C2D;
    --mdc-outlined-text-field-hover-label-text-color: black;
    --mdc-text-button-label-text-size: 16px;
    --mdc-outlined-text-field-label-text-weight: 500;
    --mdc-text-button-label-text-tracking: normal;
    --mat-text-button-state-layer-color: #DEE9CE;
    --mat-text-button-hover-state-layer-opacity: 1.00;
    --mat-tab-header-active-ripple-color: white !important;
    --mat-tab-header-inactive-ripple-color: white;
    --mat-menu-item-hover-state-layer-color: #DEE9CE !important;

    --mdc-checkbox-selected-focus-icon-color: #538200;
    --mdc-checkbox-selected-hover-icon-color: #538200;
    --mdc-checkbox-selected-icon-color: #538200;
    --mdc-checkbox-selected-pressed-icon-color: #538200;
    --mdc-checkbox-selected-checkmark-color: white;
    --mdc-checkbox-selected-focus-state-layer-color: #538200;
    --mdc-checkbox-selected-hover-state-layer-color: #538200;
    --mdc-checkbox-selected-pressed-state-layer-color: #538200;

    --mdc-outlined-text-field-error-label-text-color: #D60036;
    --mdc-outlined-text-field-error-hover-label-text-color: #D60036;
    --mat-form-field-error-text-color: #D60036;
    --mdc-outlined-text-field-error-outline-color: #D60036;

    --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.60);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.60);
    --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.20);
}

.mat-mdc-form-field-required-marker {
    color: #D60036;
}

// alert messages ------------------------------------------------
.message {
    border: 5px solid #339900;
    background-color: #339900;
}

.warning {
    border-color: #ffcc00 !important;
    background-color: #FFF9EB !important;
}

.danger {
    border: 5px solid #cc3300;
    background-color: #cc3300;
}

.message,
.warning,
.danger {
    border-width: 1px 1px 1px 5px !important;
    border-radius: 0.5rem !important;
    display: flex;
    align-items: center;
    border-style: solid !important;
    flex-direction: row !important;
    justify-content: left;
    padding: 10px;

    div {
        h2 {
            font-weight: 700;
            font-size: 17px;
            margin-bottom: 0px;
        }

        div {
            font-size: 14px;
        }
    }
}

.alert-text {
    padding-right: 20px !important;
}

.alert-icon {
    margin-right: 10px !important;
    align-self: flex-start;
    margin-top: 5px;
}

// general table formatting -------------------------------------------------------
table {
    width: 100%;

    tr {
        th {
            border-bottom-width: 2px !important;
        }
    }
}

tfoot {
    tr {
        background-color: #F7F7F7 !important;

        td {
            border-top-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
            border-top-width: var(--mat-table-row-item-outline-width, 1px);
            border-top-style: solid;
            font-weight: 700;
        }
    }
}

// general dialog formatting -------------------------------------------------------
.dialog-title {
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin-bottom: 0px !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    button {
        margin-left: auto;
        color: #2A2C2D !important;
    }
}

mat-dialog-content {
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-top-style: solid;
    border-top-width: thin;
    border-color: #C3C5C6;
    padding-top: 20px !important;
}

// general snack bar formatting -------------------------------------------------------
mat-snack-bar-container {
    padding-bottom: 30px;
}

.mdc-snackbar__surface {
    padding-left: 8px !important;
}

// mat tab formatting -----------------------------------------------------------------
.mdc-tab__text-label {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.8px !important;

    fa-icon {
        margin-right: 5px;
    }
}

mat-tab-group {
    transform: translate(0px, -48px);
    padding-left: 20px;
}

.mat-mdc-tab {
    margin-left: 5px !important;
    margin-right: 5px !important;

    .mdc-tab__ripple::before {
        opacity: .3 !important;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
    background-color: black;
}

.mat-mdc-tab.mdc-tab--active {
    .mdc-tab__content .mdc-tab__text-label fa-icon {
        color: #216E1F;
    }

    .mdc-tab__ripple::before {
        opacity: 1 !important;
        border-top-width: 4px;
        border-color: #FFBE2E;
        border-top-style: solid;
    }
}

.mat-mdc-tab-group {
    --mat-tab-header-active-ripple-color: white !important;
    --mat-tab-header-inactive-ripple-color: white !important;
    --mdc-tab-indicator-active-indicator-color: white !important;
    --mat-tab-header-active-focus-indicator-color: white !important;
    --mat-tab-header-active-hover-indicator-color: white !important;
    --mat-tab-header-inactive-label-text-color: white !important;
    --mat-tab-header-active-label-text-color: #2A2C2D !important;
    --mat-tab-header-active-hover-label-text-color: #2A2C2D !important;
    --mat-tab-header-inactive-hover-label-text-color: white !important;
}

// user formats ------------------------------------------------------------
.user-icon {
    background-color: #FFBE2E;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    padding: 6px;

    p {
        width: 20px;
        text-align: center;
    }
}

//no data for tables
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-data-icon {
        margin-top: 50px;
        color: #a4917d;

        .no-data-icon1 {
            font-size: 80px;
            width: 80px;
            height: 80px;
            position: relative;
            left: 14px;
        }

        mat-icon.no-data-icon2 {
            position: relative;
            right: 46px;
            bottom: 37px;
        }

        fa-icon.no-data-icon2 {
            font-size: 16px;
            position: relative;
            right: 44px;
            bottom: 41px;
        }

        .no-data-icon3 {
            font-size: 60px;
            width: 60px;
            height: 60px;
            position: relative;
            left: 4px;
            top: -15px;
        }

        .no-data-icon4 {
            font-size: 70px;
            width: 70px;
            height: 70px;
            position: relative;
            top: -15px;
        }
    }

    .no-data-title {
        color: #8E704F;
        font-size: 24px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    button {
        margin-bottom: 50px;
    }
}

//bar
.bar {
    border-top-color: #A0A0A3;
    border-top-width: 1px;
    border-top-style: solid;
    width: 100%;
}

//links
a:-webkit-any-link {
    color: #216E1F;
}

a:-webkit-any-link:hover {
    text-decoration: none;
}

.usa-checkbox__input {
    width: 22px;
}